import { createSlice } from '@reduxjs/toolkit';
import { storeCollectionId, storeOrganizationId } from '../utils/Storage';
import { useSelector } from 'react-redux';
import organizationsRtkApi from './api/organizations';
import collectionsRtkApi from './api/collections';
import { clearTasks } from './TasksSlice';
import { clearTasks as WFclearTasks } from './WFTasksSlice';

export const SenderType = {
    USER: "user",
    LLM: "llm"
}

export const AvatarName = {
    HUMAN: "user",
    VICUNA: "llm",
    AGENT1: "agent1",
    AGENT2: "agent2",
    AGENT3: "agent3",
}

export const UserState = {
    LOGGING_IN: "logging_in",
    LOGGING_ERROR: "logging_error",
    LOGGED_IN: "logged_in",
    LOGGED_OUT: "logged_out"
}

const initialState = {
    userState: UserState.LOGGED_IN,
    loaded: false,
    currentUserId: undefined,
    currentOrganizationId: undefined,
    currentCollectionId: undefined,
    isOrganizationIdValid: false,
    isCollectionIdValid: false,
    theme: "default"
};

export const generalSlice = createSlice({
    name: 'general',
    initialState: initialState,
    reducers: {
        setCurrentUserId: (state, action) => {
            state.currentUserId = action.payload
            import('./store').then(({ default: store }) => {
                store.dispatch(WFclearTasks());
            });
        },
        setCurrentOrganizationId: (state, action) => {
            const payload = action.payload;
            let id;
            let setCollection;
            const type = typeof (payload);
            if (type === "string" || type === "undefined") {
                id = payload;
                setCollection = true;
            }
            else {
                id = payload.id;
                setCollection = payload.setCollection;
            }

            storeOrganizationId(id);
            state.currentOrganizationId = id;

            if (state.loaded && setCollection) {
                storeCollectionId(null);
                state.currentCollectionId = null;
                import('../utils/Session').then(({ loadCollectionId }) => {
                    loadCollectionId();
                });
            }
        },
        setCurrentCollectionId: (state, action) => {
            const id = action.payload;
            storeCollectionId(id);
            state.currentCollectionId = id;
            import('./store').then(({ default: store }) => {
                store.dispatch(clearTasks());
                store.dispatch(WFclearTasks());
            });
        },
        setLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(organizationsRtkApi.endpoints.getOrganization.matchFulfilled, (state, action) => {
                state.isOrganizationIdValid = !!action.payload.name;
            })
            .addMatcher(organizationsRtkApi.endpoints.getOrganization.matchRejected, (state) => {
                state.isOrganizationIdValid = false;
            })
            .addMatcher(collectionsRtkApi.endpoints.getCollectionInfo.matchFulfilled, (state, action) => {
                state.isCollectionIdValid = !!action.payload.name;
            })
            .addMatcher(collectionsRtkApi.endpoints.getCollectionInfo.matchRejected, (state) => {
                state.isCollectionIdValid = false;
            })
    }
});

export const userStateSelector = (state) => state[generalSlice.name].userState;
const currentUserIdSelector = (state) => state[generalSlice.name].currentUserId;
export const currentOrganizationIdSelector = (state) => state[generalSlice.name].currentOrganizationId;
export const currentCollectionIdSelector = (state) => state[generalSlice.name].currentCollectionId;
const isOrganizationIdValidSelector = (state) => state[generalSlice.name].isOrganizationIdValid;
const isCollectionIdValidSelector = (state) => state[generalSlice.name].isCollectionIdValid;
export const themeSelector = (state) => state[generalSlice.name].theme;

const isOrgAdactoSelector = (state) => state[generalSlice.name].currentOrganizationId === "9b6e9413-fca0-44cf-bb35-4cfff721aed6";
const isOrgZenodotoSelector = (state) => state[generalSlice.name].currentOrganizationId === "71a10947-5a20-4392-bd92-152b524b2862";
const isOrgMarchesiniSelector = (state) => state[generalSlice.name].currentOrganizationId === "a5f36f1c-eba1-43c7-80ea-e485993e8671";
const isOrg4cSelector = (state) => (state[generalSlice.name].currentOrganizationId === "942e308b-fae0-4662-a205-4aa130265449"
    || state[generalSlice.name].currentOrganizationId === "31105fcd-a58b-43ba-8c7c-863400714bf2"
    || state[generalSlice.name].currentOrganizationId === "b66cd70b-d639-42f1-9392-108e9c65f1e3"
);
const isMmfgSelector = (state) => state[generalSlice.name].currentOrganizationId === "938d6b90-b9d2-487c-b635-34830da5244a";


export const { setCurrentUserId, setCurrentOrganizationId, setCurrentCollectionId, setLoaded, setTheme } = generalSlice.actions;

export default generalSlice.reducer;

export function useCurrentUserId() {
    return useSelector(currentUserIdSelector);
}

export function useCurrentOrganizationId() {
    return useSelector(currentOrganizationIdSelector);
}

export function useCurrentCollectionId() {
    return useSelector(currentCollectionIdSelector);
}

export function useIsOrganizationIdValid() {
    return useSelector(isOrganizationIdValidSelector);
}

export function useIsCollectionIdValid() {
    const orgValid = useIsOrganizationIdValid()
    const collValid = useSelector(isCollectionIdValidSelector);
    return orgValid && collValid;
}

export function useIsOrgAdacto() {
    return useSelector(isOrgAdactoSelector);
}

export function useIsOrgZenodoto() {
    return useSelector(isOrgZenodotoSelector);
}

export function useIsOrgMarchesini() {
    return useSelector(isOrgMarchesiniSelector);
}

export function useIsCucinelliCollection() {
    const collId = useCurrentCollectionId();
    return (
        collId === "3f7281a2-89b0-415b-9385-17170e589289"
        || collId === "5b4792b4-a3c1-412a-921f-2e4c3ffa5d6b"
        || collId === "4f2065f9-3f66-4d42-89f2-d79c638b748a"
    );
}

export function useIs4cOrganization() {
    return useSelector(isOrg4cSelector);
}

export function useIsMmfgOrganization() {
    return useSelector(isMmfgSelector);
}